export const msalConfig = {
  auth: {
    clientId: "e9d2351a-88d1-4f47-b504-da1d2fa46953",
    authority:
      "https://login.microsoftonline.com/e13312b6-4d93-4328-9691-05e7783b0ea1",
    redirectUri: import.meta.env.PROD
      ? "https://clients.bb.vision/login"
      : "http://localhost:5173/login",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};

//https://clients.bb.vision/login
