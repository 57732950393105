import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import React from "react";
import { loginRequest } from "../authConfig";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      sx={{ backgroundColor: "rgb(12, 34, 67)", textTransform: "none" }}
      onClick={() => handleLogin(instance)}
    >
      Sign In
    </Button>
  );
};
