import { useMsal } from "@azure/msal-react";
import { AppBar, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { loginRequest } from "../../authConfig";
import AlertDialog from "../ErrorDialog.jsx";

export default function ButtonAppBar({
  setAccessToken,
  withBackgroundNavbar = true,
}) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const endsInBis = location.pathname.endsWith("-bis");
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length === 0) {
      // No accounts available, handle the scenario appropriately
      return;
    }
    const request = {
      ...loginRequest,
      redirectUri: "https://clients.bb.vision/blank",
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch(() => {
        instance
          .acquireTokenPopup(request)
          .then((response) => {
            setAccessToken(response.accessToken);
          })
          .catch((error) => {
            console.error(error);
            if (error.errorCode === "popup_window_error") {
              setOpen(true);
            }
          });
      });

    /*       .catch(() => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken);
        });
      }); */
  }, [accounts]);

  return (
    <Box sx={{ width: "100vw" }}>
      <AlertDialog open={open} />
      <AppBar
        position="static"
        className="gray-navbar"
        elevation={0}
        sx={{ height: "calc(100vh / 22)", backgroundColor: "#41424c" }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: 4,
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="body1" sx={{ marginX: 1 }}>
            BB Media | Portal
          </Typography>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Home
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/Shared%20Documents/Forms/AllItems.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Reports
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/_layouts/15/news.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            News
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/Report-Bugs.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Bugs
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/About-BB.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1, width: "80px" }}
            variant="caption"
          >
            About BB
          </Link>
        </Box>
      </AppBar>
      {endsInBis ? (
        <AppBar
          position="static"
          className="gray-navbar"
          elevation={0}
          sx={{ height: "calc(100vh / 22)", backgroundColor: "red" }}
        >
          <Box
            sx={{
              display: "flex",
              marginLeft: 4,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="body2" sx={{ marginX: 1 }}>
              You are seeing the dashboard on an alternative back-up server.
              Speed may be slower.
            </Typography>
          </Box>
        </AppBar>
      ) : null}
      {withBackgroundNavbar ? (
        <AppBar
          position="absolute"
          className="navbar"
          sx={{
            backgroundColor: "rgb(12, 34, 67)",
            marginTop: "calc(100vh / 22)",
            height: "calc(100vh / 11.4285714286)",
            zIndex: -1,
          }}
        ></AppBar>
      ) : null}

      {/* <Box>
        <AppBar position="static" sx={{ backgroundColor: "rgb(12, 34, 67)" }}>
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={logo} alt="logo" width="80" />
              <Box sx={{ display: "flex", marginLeft: 4 }}>
                <Link
                  href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/es/Home.aspx"
                  color="inherit"
                  underline="none"
                  sx={{ marginX: 1 }}
                  variant="body2"
                >
                  Home
                </Link>
                <Link
                  href="https://businessbureau0.sharepoint.com/sites/BB-Portal/Shared%10Documents/Forms/AllItems.aspx"
                  color="inherit"
                  underline="none"
                  sx={{ marginX: 1 }}
                  variant="body2"
                >
                  Reports
                </Link>
                <Link
                  href="https://businessbureau0.sharepoint.com/sites/BB-Portal/_layouts/15/news.aspx"
                  color="inherit"
                  underline="none"
                  sx={{ marginX: 1 }}
                  variant="body2"
                >
                  News
                </Link>
                <Link
                  href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/Report-Bugs.aspx"
                  color="inherit"
                  underline="none"
                  sx={{ marginX: 1 }}
                  variant="body2"
                >
                  Bugs
                </Link>
                <Link
                  href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/About-BB.aspx"
                  color="inherit"
                  underline="none"
                  sx={{ marginX: 1, width: "80px" }}
                  variant="body2"
                >
                  About BB
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <Tooltip title="Log Out">
                <IconButton
                  sx={{ color: "white", marginLeft: 1 }}
                  onClick={() => signOutClickHandler(instance)}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="body2">
                Welcome, <strong>{name}!</strong>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box> */}
      <Outlet />
    </Box>
  );
}

ButtonAppBar.propTypes = {
  setReport: PropTypes.func,
  accessToken: PropTypes.string,
  setAccessToken: PropTypes.func.isRequired,
  withBackgroundNavbar: PropTypes.bool,
};
