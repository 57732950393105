import { Box } from "@mui/material";
import React from "react";
import illustration from "../../assets/stock-market-2.svg";

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 64px)",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={illustration} alt="logo" width="25%" />
    </Box>
  );
};

export default Home;
