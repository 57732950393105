import { useEffect } from "react";

const ChatwootWidget = () => {
  useEffect(() => {
    window.chatwootSettings = {
      position: "right",
      type: "expanded_bubble",
      launcherTitle: "Chat with us",
    };

    const BASE_URL = "https://chat.bb-media.com";
    const script = document.createElement("script");
    script.src = `${BASE_URL}/packs/js/sdk.js`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: "eUy3cZ3x2SgNgiKMv8LPYdcW",
        baseUrl: BASE_URL,
      });

      // Delay applying the style to ensure the widget’s CSS is loaded
      setTimeout(() => {
        const brandingLink = document.querySelector(".branding--link");
        console.log(brandingLink);
        if (brandingLink) {
          console.log("brandingLink", brandingLink);
          brandingLink.style.display = "none";
        }
      }, 10000);
    };

    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
      // Optionally, remove all styles matching `.branding--link`
      const injectedStyles = document.querySelectorAll("style");
      injectedStyles.forEach((style) => {
        if (style.innerHTML.includes(".branding--link")) {
          document.head.removeChild(style);
        }
      });
    };
  }, []);

  return null; // No UI for this component
};

export default ChatwootWidget;
